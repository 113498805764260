require('./bootstrap');
require('./menu');
require('./navigation');

// Cookies on the vehicle message
let cookie = Cookies.get('vehicleMessageModal');
if(cookie=='true'){
	console.log('cookie-true');
    $('#vehicleMessageModal').modal('hide');
} else {
	$('#vehicleMessageModal').modal('show');
}

$('.cookie-dismiss').on('click', function() {
  $('#vehicleMessageModal').addClass('cookie-hide').fadeOut();
  Cookies.set('vehicleMessageModal', 'true', { expires: 2 });
});


function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
