$('.models-nav-toggle').click(function() {
  $('.main-nav').toggleClass('models-nav-open');
});

$('.main-nav-toggle').click(function() {
  var $nav = $('.main-nav');
  $nav.toggleClass('main-nav-open');
  if ($nav.hasClass('main-nav-open')) {
    $('.page-content').on('click', function(e) {
      e.preventDefault();
      $nav.removeClass('main-nav-open');
      $(this).off('click');
    });
  }
});